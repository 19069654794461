<template>
    <div class="navbar-padding">
        <div v-if="showNavBar">
            <nav class="navbar has-shadow is-fixed-top" role="navigation" aria-label="main navigation">
                <div class="navbar-brand">
                    <div v-if="showLogoTitle" class="navbar-brand-content">
                      <div v-if="lobbyTitle || lobbyLogo" class="is-flex">
                        <img
                        v-if="lobbyLogo"
                        :src="lobbyLogo"
                        class="navbar-logo mr-2"
                        >
                        <div v-if="lobbyTitle" class="navbar-title is-size-4 has-baskerville-font has-text-weight-semibold has-text-brand-color">
                          {{ lobbyTitle }}
                        </div>
                      </div>
                      <img
                      v-else
                      @click="$router.push({ name: 'Adventures' })"
                      src="https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FPlayTours-logo-horizontal.png?alt=media&amp;token=248d7f9d-c1ca-4edc-9836-a4d3f8672c3d"
                      class="navbar-logo is-clickable"
                      >
                    </div>
                    <div v-else class="navbar-brand-content">
                      <i class="spinning fas fa-circle-notch pointer"></i>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
import firebaseApp from '@/firebase/init'

export default {
  name: 'Navbar',
  data () {
    return {
      showLogoTitle: false
    }
  },
  mounted () {
    setTimeout(() => {
      this.showLogoTitle = true
    }, 2500);
  },
  computed: {
    showNavBar() {
      return !['Welcome'].includes(this.$route.name);
    },
    lobbyTitle() {
      return this.$store.state.lobbyTitle
    },
    lobbyLogo() {
      return this.$store.state.lobbyLogo
    }
  }
}
</script>

<style scoped>
.navbar-padding {
  padding-top: 3.25rem;
}

.navbar .navbar-brand {
  text-align: center;
  display: block;
  width: 100%;
}

.navbar .navbar-brand > .navbar-item,
.navbar .navbar-brand .navbar-link {
  display: inline-block;
}

.navbar-brand-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 53px;
    /* margin-top: 7px; */
}

.navbar-title {
  font-size: clamp(0.5rem, 6.5vw, 1.3rem)
}

.navbar-logo {
  max-height: 32.59px
}
</style>