<template>
  <div>
    <Navbar/>
    <div class="columns is-gapless is-centered is-mobile px-3 mb-5">
      <div class="column is-12-mobile is-12-tablet is-12-desktop is-6-widescreen is-6-fullhd">

        <div class="level is-mobile mt-3 mb-5">
          <div class="level-left">
            <div class="level-item">
              <h3 class="title is-3 has-baskerville-font has-text-brand-color">Create Adventure</h3>
            </div>
          </div>
        </div>

        <div @click="toggleMainDetailsBox()" class="unselectable pointer has-text-weight-bold is-size-4 mb-1">
          Main Details
          <i v-if="showMainDetailsBox" class="fas fa-chevron-circle-down fa-xs ml-2"></i>
          <i v-if="!showMainDetailsBox" class="fas fa-chevron-circle-right fa-xs ml-2"></i>
        </div>
        <div v-if="showMainDetailsBox" class="box">
          <FormInput :title="'Name'" :type="'text'" :value="name" v-on:update="name = $event" :errorMessage="'asdas'"/>
          <FormInput :title="'Short Description'" :type="'text'" :value="shortDesc" v-on:update="shortDesc = $event"/>
          <FormInput :title="'Preview Image URL'" :type="'text'" :value="previewImageUrl" v-on:update="previewImageUrl = $event"/>
          <FormInput :title="'Tags'" :type="'text'" :value="tags" v-on:update="tags = $event"/>
          <FormInput :title="'Has Starting Location'" :type="'boolean'" :value="hasStartingLocationLatLon" v-on:update="hasStartingLocationLatLon = $event"/>
          <div v-if="hasStartingLocationLatLon">
            <FormInput :title="'Starting Location Name'" :type="'text'" :value="startingLocationName" v-on:update="startingLocationName = $event"/>
            <FormInput :title="'Lat'" :type="'number'" :value="lat" v-on:update="lat = $event"/>
            <FormInput :title="'Lon'" :type="'number'" :value="lon" v-on:update="lon = $event"/>
            <FormInput :title="'Radius in meters'" :type="'number'" :value="radiusInMeters" v-on:update="radiusInMeters = $event"/>
          </div>
          <FormInput :title="'Show Ending Location'" :type="'boolean'" :value="hasEndingLocationLatLon" v-on:update="hasEndingLocationLatLon = $event"/>
          <div v-if="hasEndingLocationLatLon">
            <FormInput :title="'Ending Location Name'" :type="'text'" :value="endingLocationName" v-on:update="endingLocationName = $event"/>
            <FormInput :title="'Lat'" :type="'number'" :value="endingLocationLatLon.lat" v-on:update="endingLocationLatLon.lat = $event"/>
            <FormInput :title="'Lon'" :type="'number'" :value="endingLocationLatLon.lon" v-on:update="endingLocationLatLon.lon = $event"/>
            <FormInput :title="'Radius in meters'" :type="'number'" :value="endingLocationLatLon.radius_in_meters" v-on:update="endingLocationLatLon.radius_in_meters = $event"/>
          </div>
          <FormInput :title="'Briefing'" :type="'markdown'" :value="briefing" v-on:update="briefing = $event"/>
          <FormInput :title="'Debrief'" :type="'markdown'" :value="debrief" v-on:update="debrief = $event"/>
          <FormInput :title="'Estimated Duration In Minutes'" :type="'number'" :value="estimatedDurationInMinutes" v-on:update="estimatedDurationInMinutes = $event"/>
          <FormInput :title="'Suggested Team Size'" :type="'integer'" :value="suggestedTeamSize" v-on:update="suggestedTeamSize = $event"/>
          <FormInput :title="'Time Limit In Minutes'" :type="'number'" :value="timeLimitInMinutes" v-on:update="timeLimitInMinutes = $event"/>
          <FormInput :title="'Starting Coins'" :type="'number'" :value="startingCredits" v-on:update="startingCredits = $event"/>
          <label class="label">Valid Start DateTimes</label>
          <div class="pl-3">
            <div class="box mb-3" v-for="(entry, index) in validStartDatetimes" :key="index">
              <FormInput :title="'Date From'" :type="'date'" :value="entry.dateFrom" v-on:update="$set(validStartDatetimes, index, {...entry, dateFrom: $event })"/>
              <FormInput :title="'Date To'" :type="'date'" :value="entry.dateTo" v-on:update="$set(validStartDatetimes, index, {...entry, dateTo: $event })"/>
              <FormInput :title="'Day'" :type="'day'" :value="entry.day" v-on:update="$set(validStartDatetimes, index, {...entry, day: $event })"/>
              <FormInput :title="'Start Time'" :type="'time'" :value="entry.datetimeFrom" v-on:update="$set(validStartDatetimes, index, {...entry, datetimeFrom: $event })"/>
              <FormInput :title="'End Time'" :type="'time'" :value="entry.datetimeTo" v-on:update="$set(validStartDatetimes, index, {...entry, datetimeTo: $event })"/>
              <div class="container mb-4 pb-2">
                <button
                  v-if="validStartDatetimes.length > 1"
                  @click="deleteFromArray('validStartDatetimes', index, 1)"
                  class="button is-danger is-small is-outlined is-pulled-right"
                >
                  <i class="fas fa-times pointer"></i>
                </button>
              </div>
            </div>
            <button
              @click="addToArray('validStartDatetimes', { day: null, datetimeFrom: null, datetimeTo: null })"
              class="button is-small is-fullwidth is-outlined mt-0"
            >
              <i class="fas fa-plus pointer"></i>
            </button>
          </div>
          <label class="label mt-4">Searchable Items</label>
          <div class="pl-3">
            <div class="box mb-3" v-for="(entry, index) in searchableItems" :key="index">
              <FormInput :title="'Item'" :type="'markdown'" :value="entry.item" v-on:update="$set(searchableItems, index, {...entry, item: $event })"/>
              <FormInput :title="'Scan Code'" :type="'text'" :value="entry.scanCode" v-on:update="$set(searchableItems, index, {...entry, scanCode: $event })"/>
              <FormInput :title="'Lat'" :type="'number'" :value="entry.lat" v-on:update="$set(searchableItems, index, {...entry, lat: $event })"/>
              <FormInput :title="'Lon'" :type="'number'" :value="entry.lon" v-on:update="$set(searchableItems, index, {...entry, lon: $event })"/>
              <FormInput :title="'Radius in meters'" :type="'number'" :value="entry.radius_in_meters" v-on:update="$set(searchableItems, index, {...entry, radius_in_meters: $event })"/>
              <div class="container mb-4 pb-2">
                <button
                  v-if="searchableItems.length > 0"
                  @click="deleteFromArray('searchableItems', index, 0)"
                  class="button is-danger is-small is-outlined is-pulled-right"
                >
                  <i class="fas fa-times pointer"></i>
                </button>
              </div>
            </div>
            <button
              @click="addToArray('searchableItems', {})"
              class="button is-small is-fullwidth is-outlined mt-0"
            >
              <i class="fas fa-plus pointer"></i>
            </button>
          </div>
        </div>

        <div v-for="(entry, index) in stages" :key="index">
          <div @click="toggleShowStage(index)" class="unselectable pointer has-text-weight-bold is-size-4 mb-1">
            Stage {{ index + 1 }}
            <i v-if="entry.show" class="fas fa-chevron-circle-down fa-xs ml-2"></i>
            <i v-if="!entry.show" class="fas fa-chevron-circle-right fa-xs ml-2"></i>
          </div>
          <div v-if="entry.show" class="box mb-2">
            <FormInput :title="'Briefing'" :type="'markdown'" :value="entry.briefing" v-on:update="entry.briefing = $event"/>
            <FormInput :title="'Time Limit In Minutes'" :type="'number'" :value="entry.timeLimitInMinutes" v-on:update="entry.timeLimitInMinutes = $event"/>
            <FormInput :title="'Points Needed to Pass'" :type="'integer'" :value="entry.minPoints" v-on:update="entry.minPoints = $event"/>
            <label class="label">Challenges</label>
            <div class="pl-3">
              <div class="box mb-3" v-for="(challenge, index1) in entry.challenges" :key="index1">
                <FormInput :title="'Name'" :type="'text'" :value="challenge.name" v-on:update="challenge.name = $event"/>
                <FormInput :title="'Briefing'" :type="'markdown'" :value="challenge.briefing" v-on:update="challenge.briefing = $event"/>
                <FormInput :title="'Points'" :type="'integer'" :value="challenge.points" v-on:update="challenge.points = $event"/>
                <FormInput :title="'Wrong Answer Penalty'" :type="'integer'" :value="challenge.wrongAnswerPenalty" v-on:update="challenge.wrongAnswerPenalty = $event"/>
                <FormInput :title="'Skippable'" :type="'boolean'" :value="challenge.skippable" v-on:update="challenge.skippable = $event"/>
                <FormInput :title="'Skip Penalty'" :type="'integer'" :value="challenge.skipPenalty" v-on:update="challenge.skipPenalty = $event"/>
                <FormInput :title="'Item'" :type="'markdown'" :value="challenge.item" v-on:update="challenge.item = $event"/>
                <FormInput :title="'Hint'" :type="'boolean'" :value="challenge.hasHint" v-on:update="challenge.hasHint = $event, toggleHint(index, index1, $event)"/>
                <div class="pl-3" v-if="challenge.hasHint">
                  <div class="box mb-3">
                    <FormInput :title="'Hint'" :type="'markdown'" :value="challenge.hint.hint" v-on:update="challenge.hint.hint = $event"/>
                    <FormInput :title="'Coins'" :type="'integer'" :value="challenge.hint.credits" v-on:update="challenge.hint.credits = $event"/>
                  </div>
                </div>
                <FormInput :title="'Type'" :type="'selection'" :value="challenge.type" :selections="['text', 'location', 'number', 'integer']" v-on:update="challenge.type = $event; resetAnswers(index, index1, $event)"/>
                <div v-if="challenge.type">
                  <label class="label">Answers</label>
                  <div class="pl-3">
                    <div class="box mb-3" v-for="(answer, index2) in challenge.answers" :key="index2">
                      <div v-if="challenge.type === 'text'">
                        <FormInput :title="'Value'" :type="'text'" :value="answer.value" v-on:update="answer.value = $event"/>
                        <FormInput :title="'Lenience'" :type="'number'" :value="answer.lenience" v-on:update="answer.lenience = $event"/>
                      </div>
                      <div v-if="challenge.type === 'number'">
                        <FormInput :title="'Value'" :type="'number'" :value="answer.value" v-on:update="answer.value = $event"/>
                        <FormInput :title="'Positive Lenience'" :type="'number'" :value="answer.positive_lenience" v-on:update="answer.positive_lenience = $event"/>
                        <FormInput :title="'Negative Lenience'" :type="'number'" :value="answer.negative_lenience" v-on:update="answer.negative_lenience = $event"/>
                      </div>
                      <div v-if="challenge.type === 'integer'">
                        <FormInput :title="'Value'" :type="'integer'" :value="answer.value" v-on:update="answer.value = $event"/>
                        <FormInput :title="'Positive Lenience'" :type="'integer'" :value="answer.positive_lenience" v-on:update="answer.positive_lenience = $event"/>
                        <FormInput :title="'Negative Lenience'" :type="'integer'" :value="answer.negative_lenience" v-on:update="answer.negative_lenience = $event"/>
                      </div>
                      <div v-if="challenge.type === 'location'">
                        <FormInput :title="'Lat'" :type="'number'" :value="answer.lat" v-on:update="answer.lat = $event"/>
                        <FormInput :title="'Lon'" :type="'number'" :value="answer.lon" v-on:update="answer.lon = $event"/>
                        <FormInput :title="'Radius In Meters'" :type="'number'" :value="answer.radius_in_meters" v-on:update="answer.radius_in_meters = $event"/>
                      </div>
                      <div class="container mb-4 pb-2">
                        <button
                          v-if="challenge.answers.length > 1"
                          @click="deleteAnswer(index, index1, index2)"
                          class="button is-danger is-small is-outlined is-pulled-right"
                        >
                          <i class="fas fa-times pointer"></i>
                        </button>
                      </div>
                    </div>
                    <button
                      @click="addToAnswersArray(index, index1)"
                      class="button is-small is-fullwidth is-outlined mt-0"
                    >
                      <i class="fas fa-plus pointer"></i>
                    </button>
                  </div>
                </div>
                <div v-if="entry.challenges.length > 1" class="container mb-4 pb-2">
                  <button
                    @click="deleteChallenge(index, index1)"
                    class="button is-danger is-small is-outlined is-pulled-right"
                  >
                    <i class="fas fa-times pointer"></i>
                  </button>
                </div>
              </div>
              <button
                @click="addToChallengeArray(index)"
                class="button is-small is-fullwidth is-outlined mt-0"
              >
                <i class="fas fa-plus pointer"></i>
              </button>
            </div>
            <div v-if="stages.length > 1" class="container mt-4 mb-4 pb-2">
              <button
                @click="deleteStage(index)"
                class="button is-danger is-small is-outlined is-pulled-right"
              >
                <i class="fas fa-times pointer"></i>
              </button>
            </div>
          </div>
        </div>

        <div @click="addStage()" class="unselectable pointer has-text-grey-light has-text-weight-bold is-size-4 mb-1">
          Add Stage {{ stages.length + 1 }}
          <i class="fas fa-plus-circle fa-xs ml-2"></i>
        </div>

        <div
        @click="createAdventure()"
        class="button is-success is-fullwidth mt-5"
        v-bind:class="{'is-light is-loading': loading}"
        >
          Create Adventure
        </div>
        <article v-if="message && error" class="message is-danger mt-2">
          <div class="message-header">
            <p>Error</p>
          </div>
          <pre class="message-body">
            {{ message }}
          </pre>
        </article>
        <article v-if="message && !error" class="message is-success mt-2">
          <div class="message-body">
            Successfully created adventure.
          </div>
        </article>

      </div>
    </div>
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'
import Navbar from '@/components/layout/Navbar'
import FormInput from '@/components/forms/FormInput'

const searchableItemNewData = {
  item: '',
  scanCode: null,
  lat: null,
  lon: null,
  'radius_in_meters': null
}

const answerNewDatas = {
  text: {
    value: '',
    lenience: null
  },
  number: {
    value: '',
    'positive_lenience': null,
    'negative_lenience': null,
  },
  integer: {
    value: '',
    'positive_lenience': null,
    'negative_lenience': null,
  },
  location:{
    lat: null,
    lon: null,
    'radius_in_meters': null,
  }
}

const challengeNewData = {
  name: '',
  briefing: '',
  points: '',
  wrongAnswerPenalty: null,
  skippable: false,
  skipPenalty: null,
  item: null,
  type: '',
  hasHint: false,
  hint: null,
  answers: []
}

const stageNewData = {
  show: false,
  briefing: '',
  timeLimitInMinutes: null,
  minPoints: null,
  challenges: [challengeNewData]
}

export default {
  name: 'CreateAdventure',
  components: {
    Navbar,
    FormInput
  },
  data() {
    return {
      message: '',
      loading: false,
      error: false,
      showMainDetailsBox: false,

      showOrganisationOnly: false,
      organisationId: null,
      isPlayable: true,
      name: '',
      shortDesc: '',
      previewImageUrl: '',
      tags: '',
      hasStartingLocationLatLon: true,
      startingLocationName: '',
      lat: null,
      lon: null,
      radiusInMeters: null,
      endingLocationName: null,
      hasEndingLocationLatLon: false,
      endingLocationLatLon: null,
      briefing: '',
      debrief: '',
      estimatedDurationInMinutes: null,
      suggestedTeamSize: null,
      validStartDatetimes: [{
        dateFrom: null,
        dateTo: null,
        day: null,
        datetimeFrom: null,
        datetimeTo: null
      }],
      timeLimitInMinutes: null,
      startingCredits: null,
      stages: [JSON.parse(JSON.stringify(stageNewData))],
      searchableItems: []
    }
  },
  methods: {
    change(value) {
      console.log('parent', value)
    },
    addToArray(arrayFieldName, objectData){
      this[arrayFieldName].push(objectData)
    },
    deleteFromArray(arrayFieldName, index, min){
      if(this[arrayFieldName].length > min){
        this[arrayFieldName].splice(index, 1)
      }
    },
    addToChallengeArray(stageIndex){
      this.stages[stageIndex].challenges.push(JSON.parse(JSON.stringify(challengeNewData)))
    },
    deleteChallenge(stageIndex, challengeIndex){
      const confirmed = confirm("Confirm deletion?")
      if (!confirmed) { return }
      if(this.stages[stageIndex].challenges.length > 1){
        this.stages[stageIndex].challenges.splice(challengeIndex, 1)
      }
    },
    addStage(){
      this.stages.push(JSON.parse(JSON.stringify(stageNewData)))
      this.stages[this.stages.length-1].show = true
    },
    deleteStage(stageIndex){
      const confirmed = confirm("Confirm deletion?")
      if (!confirmed) { return }
      if(this.stages.length > 1){
        this.stages.splice(stageIndex, 1)
      }
    },
    toggleHint(stageIndex, challengeIndex, hasHint){
      if(hasHint){
        this.stages[stageIndex].challenges[challengeIndex].hint = {
          hint: '',
          credits: null
        }
      } else {
        this.stages[stageIndex].challenges[challengeIndex].hint = null
      }
    },
    toggleHasEndingLocationLatLon(hasEndingLocationLatLon){
      if(hasEndingLocationLatLon){
      this.endingLocationLatLon = {
          "lat": null,
          "lon": null,
          "radius_in_meters": null
        }
      } else {
        this.endingLocationLatLon = null
      }
    },
    resetAnswers(stageIndex, challengeIndex){
      const type = this.stages[stageIndex].challenges[challengeIndex].type
      this.stages[stageIndex].challenges[challengeIndex].answers = [JSON.parse(JSON.stringify(answerNewDatas[type]))]
    },
    addToAnswersArray(stageIndex, challengeIndex){
      const type = this.stages[stageIndex].challenges[challengeIndex].type
      this.stages[stageIndex].challenges[challengeIndex].answers.push(
        JSON.parse(JSON.stringify(answerNewDatas[type]))
      )
    },
    deleteAnswer(stageIndex, challengeIndex, AnswerIndex){
      const confirmed = confirm("Confirm deletion?")
      if (!confirmed) { return }
      if(this.stages[stageIndex].challenges[challengeIndex].answers.length > 1){
        this.stages[stageIndex].challenges[challengeIndex].answers.splice(AnswerIndex, 1)
      }
    },
    toggleMainDetailsBox(){
      this.showMainDetailsBox = !this.showMainDetailsBox
      this.stages.forEach(el => {
        el.show = false
      });
    },
    toggleShowStage(index){
      this.showMainDetailsBox = false
      this.stages.forEach((el, i) => {
        if(index !== i){
          el.show = false
        }
      });
      this.stages[index].show = !this.stages[index].show
      setTimeout(() => {
        window.scrollTo(0,0)
      })
    },
    createAdventure(){
      this.message = null
      this.loading = true
      this.error = false
      const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction')
      const adventureData = {
        "show_organisation_only": this.showOrganisationOnly,
        "organisation_id": null,
        "is_playable": this.isPlayable,
        "adventure_details": {
          "name": this.name,
          "preview_image_url": this.previewImageUrl,
          "short_desc": this.shortDesc,
          "tags": this.tags,
          "starting_location_name": this.hasStartingLocationLatLon ? this.startingLocationName : null,
          "starting_location_lat_lon": this.hasStartingLocationLatLon ? {
            "lat": this.lat,
            "lon": this.lon,
            "radius_in_meters": this.radiusInMeters
          } : null,
          "ending_location_name": this.endingLocationName,
          "ending_location_lat_lon": this.endingLocationLatLon,
          "briefing": this.briefing,
          "debrief": this.debrief,
          "estimated_duration_in_minutes": this.estimatedDurationInMinutes,
          "suggested_team_size": this.suggestedTeamSize,
          "valid_start_datetimes": this.validStartDatetimes.map(x => {
            return {
              "date_from": x.dateFrom,
              "date_to": x.dateTo,
              "day": x.day,
              "time_from": x.datetimeFrom,
              "time_to": x.datetimeTo
            }
          }),
          "time_limit_in_minutes": this.timeLimitInMinutes,
          "starting_credits": this.startingCredits
        },
        "stages": this.stages.map(x => {
          return {
            "briefing": x.briefing,
            "time_limit_in_minutes": x.timeLimitInMinutes,
            "min_points": x.minPoints,
            "challenges": x.challenges.map(y => {
              return {
                "name": y.name,
                "briefing": y.briefing,
                "type": y.type,
                "answers": y.answers,
                // "answers": [
                //   {
                //     "value": "hello",
                //     "lenience": 0.4
                //   }
                // ],
                "hint": !y.hint ? null : { "credits": y.hint.credits, "hint": y.hint.hint },
                "points": y.points,
                "wrong_answer_penalty": y.wrongAnswerPenalty,
                "skippable": y.skippable,
                "skip_penalty": y.skipPenalty,
                "item": y.item
              }
            })
          }
        }),
        "searchableItems": this.searchableItems.map(x => {
          let position = null
          if(x.lat && x.lon && x['radius_in_meters']){
            position = {
              lat: x.lat,
              lon: x.lon,
              'radius_in_meters': x['radius_in_meters']
            }
          }
          return {
            item: x.item,
            scanCode: x.scanCode,
            position
          }
        })
      }
      masterFunction({
          methodName: 'create-adventure',
          adventureJson: JSON.stringify(adventureData),
          rawAdventureJson: localStorage.monabrunJson
      }).then(result => {
          console.log(result)
          this.loading = false
          if(result.data.errors){
            this.error = true
            this.message = JSON.stringify(result.data.errors, null, 4)
          } else {
            this.error = false
            this.message = JSON.stringify(result.data.message, null, 4)
          }
      }).catch(err => {
          console.log(err)
          this.loading = false
      })
    }
  },
  updated() {
    localStorage.monabrunJson = JSON.stringify({...this.$data})
  },
  mounted() {
    if(localStorage.monabrunJson) {
      const jsonObj = JSON.parse(localStorage.monabrunJson)
      for (const property in jsonObj) {
        this[property] = jsonObj[property]
      }
    }
  }
}
</script>
